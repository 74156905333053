import { useMemo, useState, useEffect } from 'react'
import classNames from 'classnames'
import CATEGORIES from '@/temp-configs/category-constants'

import { handleTag, ProductCTA } from '@saatva-bits/pattern-library.modules.detail-modal'
import { useProductState, useProductData, useAttributeOptions, ProductSelectors } from '@saatva-bits/pattern-library.modules.selection'
import { usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { useGetDiscounts, applyDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { Button } from '@saatva-bits/pattern-library.components.button'

import AddToCartButton from '@/components/ButtonAddToCart'
import DetailModalVariant from '@/components/DetailModalVariant'
import { useFavorites } from '@saatva-bits/pattern-library.modules.favorite'

import { getDetailTileArdadImages, getSwatches } from '@/utils/product'
import { affirmRefresh } from '@/utils/affirm'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

import styles from './ProductTiles.module.scss'

const createVariantUrl = (baseUrl, sku, quantity) => {
    if (!sku && !quantity) {
        return baseUrl
    }

    const encodedSku = encodeURIComponent(sku)
    const encodedQuantity = encodeURIComponent(quantity)

    return quantity ? `${baseUrl}?sku=${encodedSku}&qty=${encodedQuantity}` : `${baseUrl}?sku=${encodedSku}`
}

const ProductTile = ({ product, valuesGroups, isFilterApplied }) => {
    const productState = useProductState(product.productCode, ['assets', 'genericName', 'sku', 'productCode', 'quantity'])
    const { setFavoritesFlyoutEnabled } = useFavorites()

    const [modalOpen, setModalOpen] = useState(false)

    const isBeddingCategory = product.category.toLowerCase() === CATEGORIES.BEDDING
    const isBundleCategory = product.category.toLowerCase() === CATEGORIES.BUNDLES
    const showAddToCartUI = isBeddingCategory || isBundleCategory
    const { sitewidePromo } = usePromotionsData()
    const { enableBundleDiscounts, bundleDiscountPercent, theme } = sitewidePromo

    const { isV1: isFavoriteIconEnabled } = useExperiment('EXP.FAVORITES.DIS-530')
    const { isV1: isPromotionsMessagingEnabled } = useExperiment('EXP.PROMOTIONS_MSGING.EX-450')
    const { isV1: isPersistAttributesEnabled } = useExperiment('EXP.PERSIST_ATTRIBUTES.DIS-152')

    // TODO: use once the filter works filtering by variants update so it takes sizes into account
    // const priceRange = usePriceRange(product.variants)

    const productData = useMemo(() => {
        let lowerPrice = product.lowerPrice
        let upperPrice = product.upperPrice

        // temporarily handling bundle discounts manually. Removing extra value discounts from original min/max.
        if (isBundleCategory && enableBundleDiscounts && bundleDiscountPercent) {
            const bundleDiscount = bundleDiscountPercent / 100
            lowerPrice = product.lowestPrice - (product.lowestPrice * bundleDiscount)
            upperPrice = product.highestPrice - (product.highestPrice * bundleDiscount)
        }

        return {
            ...product,
            priceRange: {
                fullPriceMin: product.lowestPrice,
                fullPriceMax: product.highestPrice,
                finalPriceMin: lowerPrice || 0,
                finalPriceMax: upperPrice || 0,
            },
            selectedSizes: valuesGroups.selectedSizes
        }
    }, [enableBundleDiscounts, bundleDiscountPercent, isBundleCategory, product, valuesGroups.selectedSizes])

    const productCode = productData.productCode

    const onAddToCart = () => {
        setModalOpen(false)
    }

    const customOptionProps = {
        'bathSet': {
            toggleProps: {
                modifyOptionLabel: (label) => { return label.replace('Bath', `\nBath`) },
                autoFitColumnLayoutOverride: true
            }
        },
    }

    const attributesWithCustomSetup = ['color', 'fabric', 'scent', 'quantity']
    const hiddenAttributes = ['quantity']

    productData.options.forEach(option => {
        // Create customProps to modify default behavior specific attributes
        if (!attributesWithCustomSetup.includes(option.code)) {
            customOptionProps[option.code] = { displayType: 'toggle' }
            !showAddToCartUI && hiddenAttributes.push(option.code)
        }
        // Create customProps to modify default selectors label based on category
        else if (!isBeddingCategory) {
            customOptionProps[option.code] = {
                toggleProps: {
                    label: option.displayName,
                    isCustomLabel: true
                }
            }
        }
    })

    const persistCurrentVariant = isPersistAttributesEnabled && (isFilterApplied || (productState.quantity && productState.quantity != 1))
    const shopUrl = persistCurrentVariant ? createVariantUrl(productData.shopCta, productState.sku, productState.quantity) : productData.shopCta
    const productUrl = persistCurrentVariant ? createVariantUrl(product.url, productState.sku, productState.quantity) : product.url
    const learnMoreUrl = persistCurrentVariant ? createVariantUrl(product.url, productState.sku, productState.quantity) : createVariantUrl(product.url, productState.sku)

    const addToCartSection = (
        <div className={styles.addToCartSection}>
            {showAddToCartUI &&
                <AddToCartButton
                    productCode={productCode}
                    onAddToCart={onAddToCart}
                    hasQtySelector={true}
                >
                    Add to Cart
                </AddToCartButton>
            }
            <ProductCTA
                productCode={productCode}
                selectionDeps={{ useProductState }}
                shopCta={!showAddToCartUI ? shopUrl : ''} />
            {showAddToCartUI &&
                <Button kind="secondary" href={learnMoreUrl} data-selector="learnMoreButton">
                    Learn more
                </Button>
            }
        </div>
    )

    const buystack = (
        <ProductSelectors
            className={styles.detailModalSelectors}
            productCode={productCode}
            customOptionProps={customOptionProps}
            shortDropdowns={true}
            hiddenAttributes={hiddenAttributes}
            isOutOfStockStyling={isBeddingCategory} />
    )

    const { defaultImage, hoverImage } = getDetailTileArdadImages(productData.ardadDescriptors, productState)
    const { defaultImage: thumbnailImage } = getDetailTileArdadImages(productData.ardadDescriptors, productState, '1-1')

    const options = useAttributeOptions(productCode, productData.swatchAttribute)

    const onSwatchChange = (swatch) => {
        options.setAttributeValue(swatch)
    }
    const swatches = getSwatches(options, options?.selectedValue, onSwatchChange, productCode)

    const tag = handleTag(productData.isNew, productData.tag)

    const affirmPrice = !isPromotionsMessagingEnabled && product.category.toLowerCase() !== CATEGORIES.BEDDING ? { price: productData.priceRange.finalPriceMin } : null

    const containerClassNames = classNames({
        'u-marginBottom--2xl': !swatches && !affirmPrice,
        'u-marginBottom--xs': swatches
    })

    const modalContainerProps = {
        closeButtonClasses: styles.closeButton,
        modalClasses: styles.detailModalContainer
    }

    const imageCTA = {
        text: 'View Details',
        onClick: () => {
            setFavoritesFlyoutEnabled(null)
            setModalOpen(true)
        }
    }

    let discountLabels = null

    if (isPromotionsMessagingEnabled) {
        if (sitewidePromo.enableBundleDiscounts && isBundleCategory) {
            // Override value discount labels for bundle sale, DEF-27835
            discountLabels = [{
                text: `${sitewidePromo.bundleDiscountPercent}% Off Bundles`,
                discountType: 'value',
                theme
            }]
        } else if (product.discountLabels?.length) {
            discountLabels = product.discountLabels.map(label => ({
                text: label.label,
                discountType: label.discountType,
                theme
            }))
        }
    }

    return <>
        <DetailProductTile
            imageCTA={imageCTA}
            title={{ text: productData.title, href: productUrl, titleClassName: styles.title }}
            subtitle={{ text: productData.subtitle, className: styles.subtitle }}
            priceRange={productData.priceRange}
            discountLabels={discountLabels}
            imageHref={productUrl}
            affirm={affirmPrice}
            defaultImage={defaultImage}
            hoverImage={hoverImage}
            swatches={swatches}
            containerClassName={containerClassNames}
            imageBadgeLeft={tag && { text: tag }}
            productCode={productCode}
            location='PLP Product Tile'
            enableFavorites={isFavoriteIconEnabled}
            category={productData.category}
            subCategory={productData.subcategory}
            thumbnailImage={thumbnailImage}
            dataSelector={`product-tile-${productData.productCode}`}
        />
        <DetailModalVariant
            isOpen={modalOpen}
            closeHandler={() => setModalOpen(false)}
            productData={productData}
            buystack={buystack}
            addToCartSection={addToCartSection}
            promotionDeps={{
                useGetDiscounts,
                applyDiscounts
            }}
            selectionDeps={{
                useProductData,
                useProductState,
                useAttributeOptions
            }}
            modalContainerProps={modalContainerProps}
        />
    </>
}

/**
 * @param {object} props
 * @param {any[]} props.products
 * @param {{ selectedSizes: string[]?, selectedColors: string[]? }} props.valuesGroups - Values selected by user that change content shown in page
 * @param {string} props.category - Category of the products
 * @param {Function} props.clearFilters - Function to clear filters
 * @param {boolean} props.isFilterApplied - Whether any filters are applied
 */
export default function ProductTiles({ products, valuesGroups, clearFilters, isFilterApplied }) {

    useEffect(() => {
        affirmRefresh(100)
    })

    if (products.length <= 0) {
        return <div className='col col--xs-12 col--sm-8 col--md-6 col--lg-4'>
            <div className={styles.noProductsTile}>
                <h2>We couldn’t find any available products with the filters you selected. Please try clearing your filters to see available options. <button className={styles.clearButton} onClick={clearFilters}>Clear Filters</button></h2>
            </div>
        </div>
    }

    return products.map((product) => (
        <div key={product.productCode} className={'col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl'}>
            <ProductTile
                product={product}
                valuesGroups={valuesGroups}
                isFilterApplied={isFilterApplied}
            />
        </div>
    ))
}
